import React from "react"

import BlockContent from "@sanity/block-content-to-react"

import { Flex, Block } from "@sonos-inc/web-ui-core"
import Label from "../Label"
import { H1 } from "../typography"

import { presentDate } from "../../utils/date"
import CategoryLinks from "../CategoryLink"

export default function HeaderMeta({
  title,
  categories,
  publishedAt,
  authors,
  defaultGridColumn,
  textGridColumn,
  mb,
}) {
  const withAuthors = authors?.length > 0
  return (
    <>
      <Flex
        gridColumn={defaultGridColumn}
        mb={["medium", null, "big"]}
        justifyContent="space-between"
      >
        <Block>
          <CategoryLinks categories={categories} />
        </Block>
        {publishedAt && <Label flex="none">{presentDate(publishedAt)}</Label>}
      </Flex>

      <H1
        gridColumn={textGridColumn}
        mb={withAuthors ? ["medium", null, "big"] : mb}
      >
        {title}
      </H1>

      {withAuthors && (
        <Block gridColumn={defaultGridColumn} mb={mb}>
          {authors.map((author, idx) => {
            const content = (
              <>
                <Block fontWeight="medium" mb="small" textStyle="ts1">
                  {author.name}
                </Block>
                {author._rawBio && (
                  <Label>
                    <BlockContent blocks={author._rawBio} />
                  </Label>
                )}
              </>
            )
            const isLast = idx === authors.length - 1
            return isLast ? (
              <React.Fragment key={idx}>{content}</React.Fragment>
            ) : (
              <Block key={idx} mb={"large"}>
                {content}
              </Block>
            )
          })}
        </Block>
      )}
    </>
  )
}
