import { useStaticQuery, graphql } from "gatsby"

export const useLatestPosts = () => {
  const { allSanityPost } = useStaticQuery(graphql`
    query {
      allSanityPost(
        filter: {
          slug: { current: { ne: null } }
          isFuture: { eq: false }
          publishedAt: { ne: null }
        }
        sort: { fields: [publishedAt], order: [DESC] }
        limit: 3
      ) {
        nodes {
          ...PostDataFragment
        }
      }
    }
  `)

  return allSanityPost.nodes
}
