import React, { useEffect, useState } from "react"
import { Block, Flex, styled } from "@sonos-inc/web-ui-core"
import { isBrowser } from "../../utils/isBrowser"

const TwitterIcon = () => {
  return (
    <svg viewBox="0 0 32 32" role="img">
      <g>
        <path
          d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  )
}

const LinkedinIcon = () => {
  return (
    <svg viewBox="0 0 32 32" role="img">
      <g>
        <path
          d="M26 25.963h-4.185v-6.55c0-1.56-.027-3.57-2.175-3.57-2.18 0-2.51 1.7-2.51 3.46v6.66h-4.182V12.495h4.012v1.84h.058c.558-1.058 1.924-2.174 3.96-2.174 4.24 0 5.022 2.79 5.022 6.417v7.386zM8.23 10.655a2.426 2.426 0 0 1 0-4.855 2.427 2.427 0 0 1 0 4.855zm-2.098 1.84h4.19v13.468h-4.19V12.495z"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  )
}

const FacebookIcon = () => {
  return (
    <svg viewBox="0 0 32 32" role="img">
      <g>
        <path
          d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  )
}

const ShareText = styled(Block)({
  color: "#979797",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "21px",
  marginRight: "64px",
})

const StyledLink = styled("a")({
  width: "24px",
  height: "24px",
  marginRight: "16px",
})

export default function ShareButton() {
  const [encodedUrl, setEncodedUrl] = useState("")

  useEffect(() => {
    if (!isBrowser) return
    setEncodedUrl(encodeURIComponent(window.location.href))
  }, [])

  return (
    <Block
      gridColumn={["2 / 25", null, "4 / 23", "10 / 23"]}
      mt={["small", null, "medium"]}
    >
      <Flex alignItems="center">
        <ShareText>Share</ShareText>
        <StyledLink
          href={`https://twitter.com/intent/tweet/?url=${encodedUrl}`}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon />
        </StyledLink>
        <StyledLink
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinIcon />
        </StyledLink>
        <StyledLink
          href={`https://facebook.com/sharer/sharer.php?u=${encodedUrl}`}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </StyledLink>
      </Flex>
    </Block>
  )
}
