const PEOPLE_CATEGORY_ID = "9660ddcb-55d0-452b-8ca6-b7930ce010f0"
const INTERVIEW_CATEGORY_ID = "497bb7e9-f57c-4dc7-b271-e35fc6b947b2"

const PORTRAIT_IMAGE_GROUP_MAX_LENGTH = 3

const MAX_RELATED_CATEGORIES = 2
const MAX_ARTICLES_PER_SECTION = 2
/*
 case 1: no related category (show 2 recent posts) => {"More from Sonos Tech Blog" : []}
 case 2: one related category (max 2 first articles)) => { "Continue ... categoryTitle": [article1, article2] }
 case 3: more than one related category (show max 4 articles, each category max 2 first articles) => { "Continue ... categoryTitle1": [], "Continue ... categoryTitle2": [] }
 */
export function computeReadMorePosts(
  allCategoryMatchedPost,
  currentPost,
  latestPosts
) {
  let result = {}

  // case 1
  if (!allCategoryMatchedPost.length) {
    if (latestPosts.length) {
      result["More from Sonos Tech Blog"] = latestPosts
        .filter(({ id }) => id !== currentPost.id)
        .slice(0, MAX_ARTICLES_PER_SECTION)
    }
    return result
  }

  // case 2 and case 3
  const currentPostCategories = currentPost.categories.map(({ title }) => title)
  const inResultPost = []

  allCategoryMatchedPost.forEach((sanityPost) => {
    // exclude self
    if (sanityPost.id === currentPost.id) return

    // find related posts
    const sanityPostCategories = sanityPost.categories.map(({ title }) => title)

    currentPostCategories.forEach((currentPostCategory) => {
      // exclude duplicate articles
      if (inResultPost.includes(sanityPost.id)) return
      // found a category match
      if (sanityPostCategories.includes(currentPostCategory)) {
        const title = `Continue reading in ${currentPostCategory}`
        if (!result[title]) {
          // max 2 related categories
          if (Object.keys(result).length === MAX_RELATED_CATEGORIES) return
          result[title] = [sanityPost]
          inResultPost.push(sanityPost.id)
          return
        }
        // max 2 articles in each related category
        if (result[title].length < MAX_ARTICLES_PER_SECTION) {
          result[title].push(sanityPost)
          inResultPost.push(sanityPost.id)
        }
      }
    })
  })

  return result
}

// NOTE: what is called "id" in Sanity,
// is found under the "_id" field via graphql..
// There's also another "id" field via graphql that DOES NOT MATCH Sanity!
export function isInterviewPost(categories) {
  return (
    categories &&
    categories.find(
      ({ _id }) => _id === PEOPLE_CATEGORY_ID || _id === INTERVIEW_CATEGORY_ID
    )
  )
}

// detects a sequence of up to 3 portrait s3Images,
// groups them together as a portraitImageGroup block _type.
export function prepareBodyCopy(bodyCopy) {
  let portraitImageGroupBuffer = []
  const lastIndex = bodyCopy.length - 1

  function addToImageGroupBuffer(acc, block, idx) {
    portraitImageGroupBuffer.push(block)

    if (
      portraitImageGroupBuffer.length === PORTRAIT_IMAGE_GROUP_MAX_LENGTH ||
      idx === lastIndex
    ) {
      flushImageGroupBuffer(acc)
    }
  }
  function flushImageGroupBuffer(acc) {
    if (portraitImageGroupBuffer.length === 1) {
      // push as a regular image - no sequence of portrait images detected
      acc.push(portraitImageGroupBuffer[0])
      portraitImageGroupBuffer = []
    } else if (portraitImageGroupBuffer.length > 1) {
      acc.push({
        _type: "portraitImageGroup",
        nodes: portraitImageGroupBuffer,
      })
      portraitImageGroupBuffer = []
    }
  }

  return bodyCopy.reduce((acc, block, idx) => {
    const { _type, metadata } = block

    if (
      _type === "sonosImage" &&
      // isPortraitOrientation
      metadata?.dimensions?.aspectRatio < 1
    ) {
      addToImageGroupBuffer(acc, block, idx)
    } else {
      flushImageGroupBuffer(acc)

      acc.push(block)
    }
    return acc
  }, [])
}
