import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import BlogPost from "../components/BlogPost"

export default function BlogPostPageTemplate({
  data: { sanityPost, allCategoryMatchedPost },
}) {
  return (
    <Layout>
      <BlogPost
        {...sanityPost}
        allCategoryMatchedPost={allCategoryMatchedPost.nodes}
      />
    </Layout>
  )
}

export const blogPostData = graphql`
  query ($id: String!, $categories: [String]) {
    sanityPost(id: { eq: $id }) {
      ...PostDataFragment
      noHeaderImage
      authors {
        name
        _rawBio
      }
      _rawBodyCopy(resolveReferences: { maxDepth: 5 })
    }
    allCategoryMatchedPost: allSanityPost(
      filter: {
        id: { ne: $id }
        slug: { current: { ne: null } }
        isFuture: { eq: false }
        publishedAt: { ne: null }
        categories: { elemMatch: { title: { in: $categories } } }
      }
      sort: { fields: [publishedAt], order: [DESC] }
      limit: 10
    ) {
      nodes {
        ...PostDataFragment
      }
    }
  }
`
