import React, { Fragment } from "react"
import { Block, Grid } from "@sonos-inc/web-ui-core"
import BlogpostSummary, {
  desktopRegularGridTemplateColumns,
  tabletRegularGridTemplateColumns,
} from "../BlogpostSummary"
import { useLatestPosts } from "./hooks"
import { PAsBlock, UnstyledBlockUl } from "../blocks"
import { H3 } from "../typography"
import { computeReadMorePosts } from "./tools"
import {
  defaultMobileGridContentColumn,
  defaultMobileGridTemplateColumns,
} from "../../constants/layout"

export default function ReadMore({ id, categories, allCategoryMatchedPost }) {
  const latestPosts = useLatestPosts()
  const readMorePosts = computeReadMorePosts(
    allCategoryMatchedPost,
    { id, categories },
    latestPosts
  )

  return (
    <Block mb={["huge", null, "64px"]}>
      {Object.keys(readMorePosts).length
        ? Object.keys(readMorePosts).map((key) => (
            <Fragment key={key}>
              <Grid
                gridTemplateColumns={[
                  defaultMobileGridTemplateColumns,
                  null,
                  tabletRegularGridTemplateColumns,
                  desktopRegularGridTemplateColumns,
                ]}
              >
                <H3
                  Element={PAsBlock}
                  mb={["32px", null, "48px"]}
                  gridColumn={[
                    defaultMobileGridContentColumn,
                    null,
                    "image-start / meta-end",
                  ]}
                >
                  {key}:
                </H3>
              </Grid>
              <UnstyledBlockUl>
                {readMorePosts[key].map((post) => (
                  <BlogpostSummary key={post.id} Element="li" {...post} />
                ))}
              </UnstyledBlockUl>
            </Fragment>
          ))
        : null}
    </Block>
  )
}
