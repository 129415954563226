import React, { Fragment } from "react"
import BlockContent from "@sanity/block-content-to-react"
import { Image, Grid, Block, useTheme } from "@sonos-inc/web-ui-core"
import "katex/dist/katex.min.css?raw"

import ReadMore from "./ReadMore"
import HeaderMeta from "./HeaderMeta"
import SEO from "../SEO"
import serializers from "../portableTextSerializers"
import {
  defaultMobileGridTemplateColumns,
  defaultMobileGridContentColumn,
} from "../../constants/layout"
import { isInterviewPost, prepareBodyCopy } from "./tools"
import ShareButton from "./ShareButtons"

const desktopHeaderGridTemplateColumns =
  "[start] repeat(6, 1fr) [header-start] repeat(14, 1fr) [text-end] repeat(4, 1fr) [header-end] repeat(3, 1fr)"
const tabletHeaderGridTemplateColumns =
  "[start] repeat(2, 1fr) [header-start] repeat(14, 1fr) [text-end] repeat(5, 1fr) [header-end] repeat(2, 1fr)"

export default function BlogPostPage({
  id,
  title,
  authors,
  publishedAt,
  categories,
  noHeaderImage,
  mainImage,
  _rawBodyCopy,
  isPreview,
  allCategoryMatchedPost,
}) {
  const isInterview = isInterviewPost(categories)
  const withAuthors = authors?.length > 0
  const { path, metadata: imgMetadata } = mainImage?.asset || {}
  const imgUrl = process.env.GATSBY_ASSET_BASE_URL + path

  const theme = useTheme()

  return (
    <Fragment>
      <SEO title={title} imageShare={imgUrl} />
      <Grid
        gridTemplateColumns={[
          defaultMobileGridTemplateColumns,
          null,
          tabletHeaderGridTemplateColumns,
          desktopHeaderGridTemplateColumns,
        ]}
      >
        <HeaderMeta
          defaultGridColumn={[
            defaultMobileGridContentColumn,
            null,
            "header-start / header-end",
          ]}
          textGridColumn={[
            defaultMobileGridContentColumn,
            null,
            "header-start / text-end",
          ]}
          title={title}
          categories={categories}
          publishedAt={publishedAt}
          authors={authors}
          mb={["big", null, withAuthors ? "48px" : "64px"]}
        />
        {/* Note: conditional row placement of this image block */}
        {!noHeaderImage && imgUrl && (
          <Block
            gridColumn={[
              defaultMobileGridContentColumn,
              null,
              `header-start / ${isInterview ? "text-end" : "header-end"}`,
            ]}
            gridRow={1}
            mb={["medium", null, isInterview ? "48px" : "big"]}
          >
            <Image
              loading="lazy"
              src={imgUrl}
              aspectRatio={imgMetadata?.dimensions?.aspectRatio}
              lqipUri={imgMetadata?.lqip}
              lqipColor={imgMetadata?.palette?.dominant?.background}
              alt={mainImage?.altText}
            />
          </Block>
        )}
      </Grid>

      <Grid
        gridTemplateColumns={`[lg] 2fr repeat(${
          theme.grid.columns - 4
        }, [col-start] 1fr) [rg] 2fr`}
        mb={["40px", null, "48px"]}
      >
        <BlockContent
          blocks={prepareBodyCopy(_rawBodyCopy)}
          serializers={serializers}
        />
        <ShareButton />
      </Grid>

      {!isPreview && (
        <ReadMore
          id={id}
          categories={categories}
          allCategoryMatchedPost={allCategoryMatchedPost}
        />
      )}
    </Fragment>
  )
}
